const routesConstants = {
  HOME: "",
  // common
  DISCOVER: "discover",
  BATTLEGROUND: "battleground",
  BATTLE: "battle",
  LEADER_BOARD: "leaderboard",
  TERMS_AND_CONDITION: "terms-and-conditons",
  PRIVACY_POLICY: "privacy-policy",
  // public
  LOGIN: "login",
  SIGN_UP: "sign-up",
  FORGOTTEN_PASSWORD: "forgotten-password",
  // private
  BILLING: "billing",
  SETTINGS: "settings",
  PROFILE: "profile",
  // admin user role private routes
  ARTISTS_LISTING: "artists-listings",
  BATTLES_LISTING: "battles-listings",
  BATTLES_CREATE: "battles-create",
  BATTLES_EDIT: "battles-edit",
  BATTLES_ENTRANTS: "battle-entrants",
  ARTISTS_ENTRANTS: "artist-entrants",
  DASHBOARD: "admin-dashboard",
  SPONSOR: "sponsor",
  PARTNERS: "partners",
  XP_DETAILS: "xp-details",
};

export default routesConstants;
