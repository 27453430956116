import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import Cookies, { cookieKeys } from "services/cookies";
import { Collection } from "constants/Collection";
import { db } from " firebase";

import { onSnapshot, serverTimestamp } from "firebase/firestore";

import {
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { handleSendMail } from "utils/handleSendMail";
import { emailConstants } from "constants/EmailConstants";
import { updateXPDetails } from "modules/adminUserRole/features/adminUserSlice";

const initToken = () => Cookies.get(cookieKeys.TOKEN);
const initUser = () => Cookies.get(cookieKeys.USER);
const initGuestUser = () => Cookies.get(cookieKeys.GUEST_USER);

const initialState = () => ({
  loading: false,
  error: false,
  token: initToken() || "",
  isAuth: !!initToken(),
  user: initUser(),
  sectionRef: null,
  guestUser: initGuestUser(),
});

export const signup = createAsyncThunk(
  "auth/signup",
  async (request, { dispatch }) => {
    try {
      await setDoc(
        doc(db, Collection.USERS, request.doc),
        {
          ...request.body,
          created: serverTimestamp(),
          createdAt: serverTimestamp(),
        },
        {
          merge: true,
        },
      );
      if (request?.body?.role === "creator") {
        let to = [
          { email: request?.body?.email, name: request?.body?.displayName },
        ];
        let payload = handleSendMail(to, emailConstants.WELCOME_EMAIL);
        dispatch(sendMail(payload));
      } else if (request?.body?.role === "voter") {
        let to = [
          { email: request?.body?.email, name: request?.body?.displayName },
        ];
        let payload = handleSendMail(to, emailConstants.VOTER_WELCOME_EMAIL);
        dispatch(sendMail(payload));
      }

      let to = [
        { email: emailConstants.ADMIN_EMAIL, name: emailConstants.ADMIN_NAME },
      ];
      let name = request?.body?.displayName;
      name = name
        .toLowerCase()
        .replace(/(^|\s)\S/g, letter => letter.toUpperCase());
      emailConstants.ADMIN_NEW_USER_NOTIFICATION_EMAIL = {
        ...emailConstants.ADMIN_NEW_USER_NOTIFICATION_EMAIL,
        PARAGRAPH1: `We've got a new member onboard! Let's give a warm welcome to ${name}.`,
        PARAGRAPH2: `Here are the details:<br>Name: ${name}<br>Email: ${request?.body?.email}<br>Membership status: Free<br>Role: ${request?.body?.role}`,
        PARAGRAPH3: `Let's make ${name} feel right at home in our vibrant community.`,
      };
      let emailPayload = handleSendMail(
        to,
        emailConstants.ADMIN_NEW_USER_NOTIFICATION_EMAIL,
      );
      dispatch(sendMail(emailPayload));

      let payload = { doc: request.doc, incrementValue: 1500 };
      dispatch(updateXPDetails(payload));
    } catch (e) {
      console.log(e);
    }
  },
);

export const addGuestUser = createAsyncThunk(
  "auth/addGuestUser",
  async (payload, api) => {
    try {
      await setDoc(doc(db, Collection.USERS, payload.doc), payload.body, {
        merge: true,
      });
    } catch (e) {
      console.log(e);
    }
  },
);

export const addGuestUserIP = createAsyncThunk(
  "auth/addGuestUserIP",
  async (payload, { dispatch }) => {
    try {
      await addDoc(collection(db, Collection.GUEST_USERS), {
        ...payload,
        createdAt: serverTimestamp(),
      });
      dispatch(getGuestById(payload));
    } catch (error) {
      console.log("error ", error);
      // return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const getGuestById = createAsyncThunk(
  "auth/getGuestById",
  async (request, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.GUEST_USERS),
        where("ipAddress", "==", request?.ipAddress),
      );
      const docSnap = await getDocs(q);
      docSnap.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });

      // eslint-disable-next-line

      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);
export const updateGuest = createAsyncThunk(
  "auth/updateGuest",
  async (payload, { dispatch }) => {
    try {
      await setDoc(doc(db, Collection.GUEST_USERS, payload.doc), payload.body, {
        merge: true,
      });

      dispatch(getGuestById({ ipAddress: payload?.body?.ipAddress }));
    } catch (e) {
      console.log(e);
    }
  },
);

//getting list of artists
export const getUser = createAsyncThunk(
  "auth/getUser",
  async (request, { dispatch }) => {
    try {
      const docRef = doc(db, Collection.USERS, request.doc);
      const docSnap = await getDoc(docRef);
      let obj = docSnap.data();
      obj["id"] = docSnap.id;
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(docRef, snapShot => {
        const newData = { ...snapShot.data(), id: snapShot.id };
        dispatch(getUser.fulfilled(newData));
      });
      return obj;
    } catch (e) {
      console.log(e);
    }
  },
);

//update the user
export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async (payload, { dispatch }) => {
    try {
      await setDoc(doc(db, Collection.USERS, payload.doc), payload.body, {
        merge: true,
      });

      if (payload?.isSubscribed) {
        let to = [
          {
            email: emailConstants.ADMIN_EMAIL,
            name: emailConstants.ADMIN_NAME,
          },
        ];
        let name = payload?.body?.displayName;
        name = name
          .toLowerCase()
          .replace(/(^|\s)\S/g, letter => letter.toUpperCase());

        emailConstants.ADMIN_NEW_PAID_USER_NOTIFICATION_EMAIL = {
          ...emailConstants.ADMIN_NEW_PAID_USER_NOTIFICATION_EMAIL,
          PARAGRAPH1: `Exciting news! We have a new paid member joining our community. Let's extend a warm welcome to ${name}.`,
          PARAGRAPH2: `Here are the details:<br><br>Name: ${name}<br>Email: ${payload?.body?.email}<br>Membership status: ${payload?.body?.subscription?.status}<br>Role: ${payload?.body?.role}`,
          PARAGRAPH3: `Let's ensure ${name} feels the full benefits of our vibrant community as a valued paid member.`,
        };
        let emailPayload = handleSendMail(
          to,
          emailConstants.ADMIN_NEW_PAID_USER_NOTIFICATION_EMAIL,
        );
        dispatch(sendMail(emailPayload));
      }
      if (payload?.body?.avatar && payload?.updateImage) {
        let payload1 = { doc: payload.doc, incrementValue: 500 }; //static xp value for upload photo
        dispatch(updateXPDetails(payload1));
      }
      dispatch(getUser({ doc: payload.doc }));
    } catch (e) {
      console.log(e);
    }
  },
);
export const getUserByEmail = createAsyncThunk(
  "auth/getUserByEmail",
  async (payload, api) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.USERS),
        where("email", "==", payload?.email),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);
export const voterLogin = createAsyncThunk(
  "auth/voterLogin",
  async (payload, api) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.USERS),
        where("email", "==", payload?.email),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["role"] = "voter";
        obj["id"] = doc.id;
        arr.push(obj);
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

export const sendMail = createAsyncThunk(
  "auth/sendMail",
  async (payload, api) => {
    try {
      const docRef = await addDoc(collection(db, Collection.EMAILS), {
        ...payload,
        created: serverTimestamp(),
      });
      payload["id"] = docRef.id;
    } catch (e) {
      console.log(e);
    }
  },
);

const loginSlice = createSlice({
  initialState: initialState(),
  name: "auth",
  reducers: {
    login: (state, { payload }) => {
      state.token = payload?.token;
      Cookies.set(cookieKeys.TOKEN, state.token);
    },
    logout: (state, { payload }) => {
      state.isAuth = false;
      state.token = "";
      state.user = "";
    },
    changeIsAuth: (state, { payload }) => {
      state.isAuth = payload;
    },
    setRef: (state, { payload }) => {
      state.sectionRef = payload;
    },
    guestLogin: (state, { payload }) => {
      state.user = initUser();
    },
  },
  extraReducers: builder => {
    builder.addCase(signup.pending, state => {
      state.loading = true;
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.user = initUser();
      state.token = initToken();
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    builder.addCase(updateUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    builder.addCase(addGuestUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(addGuestUser.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addGuestUser.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    // getuser details
    builder.addCase(getUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuth = true;
      Cookies.set(cookieKeys.USER, action.payload);
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });

    builder.addCase(getGuestById.rejected, (state, action) => {
      state.guestUser = null;
    });
    builder.addCase(getGuestById.fulfilled, (state, action) => {
      state.guestUser =
        Array.isArray(action.payload) && action.payload.length > 0
          ? action.payload[0]
          : null;
      Cookies.set(cookieKeys.GUEST_USER, state.guestUser);
    });
    builder.addCase(getGuestById.pending, (state, action) => {
      state.guestUser = null;
    });
  },
});

export const { login, changeIsAuth, logout, setRef, guestLogin } =
  loginSlice.actions;

export default loginSlice.reducer;
