import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from " firebase";
import { onSnapshot, serverTimestamp } from "firebase/firestore";
import {
  doc,
  setDoc,
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Collection } from "constants/Collection";
import { updateXPDetails } from "modules/adminUserRole/features/adminUserSlice";
import { createPlayList } from "modules/battleground/features/battleGroundSlice";
import { emailConstants } from "constants/EmailConstants";
import { sendMail } from "modules/login/features/loginSlice";
import { handleSendMail } from "utils/handleSendMail";

const initialState = {
  loading: false,
  battleList: [],
  battleItem: "",
  battleSongList: [],
  userLikedSongs: [],
};

//getting list of artists

export const getBattleItem = createAsyncThunk(
  "battle/getBattleItem",
  async (payload, { dispatch }) => {
    try {
      let { callback } = payload;
      let arr = [];
      const q = query(
        collection(db, Collection.BATTLES),
        where(payload.param, payload.condition, payload.target),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        callback(newData);
        dispatch(getBattleItem.fulfilled(newData));
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getSongs = createAsyncThunk(
  "battle/getSongs",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.SONGS),
        where("battleId", "==", payload?.battleId),
        orderBy("likes", "desc"),
        limit(payload.limit),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getSongs.fulfilled(newData));
      });
      let payload1 = {
        songList: arr,
      };
      dispatch(createPlayList(payload1));
      return arr;
    } catch (e) {
      console.log(e, "index");
    }
  },
);
export const getUserLikedSongs = createAsyncThunk(
  "battle/getUserLikedSongs",
  async (payload, api) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.USERS, payload?.doc, Collection.LIKES),
        where("battleId", "==", payload?.battleId),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

export const uploadSongs = createAsyncThunk(
  "battle/uploadSongs",
  async (payload, { dispatch }) => {
    try {
      const docRef = await addDoc(collection(db, Collection.SONGS), {
        ...payload.body,
        created: serverTimestamp(),
      });
      payload["id"] = docRef.id;
      let payload1 = { doc: payload?.body?.userId, incrementValue: 2000 };
      dispatch(updateXPDetails(payload1));

      if (payload?.body?.battleId) {
        let to = [
          {
            email: emailConstants.ADMIN_EMAIL,
            name: emailConstants.ADMIN_NAME,
          },
        ];
        let name = payload?.displayName;
        name = name
          .toLowerCase()
          .replace(/(^|\s)\S/g, letter => letter.toUpperCase());
        emailConstants.ADMIN_NEW_BATTLE_NOTIFICATION_EMAIL = {
          ...emailConstants.ADMIN_NEW_BATTLE_NOTIFICATION_EMAIL,
          PARAGRAPH1: `We've got an exciting update! A new participant has registered for a battle. Let's give a warm welcome to ${name}.`,
          PARAGRAPH2: `Here are the details:<br>Name: ${name}<br>Email: ${payload?.email}<br>Membership status:${payload.isPaid ? " Paid" : " Free"}<br>Joined Battle: ${payload?.battleTitle}`,
          PARAGRAPH3: `Let's make sure ${name} feels the energy of our community as she joins in the celebration.`,
        };
        let emailPayload = handleSendMail(
          to,
          emailConstants.ADMIN_NEW_BATTLE_NOTIFICATION_EMAIL,
        );
        dispatch(sendMail(emailPayload));
      }

      Promise.resolve(payload);
    } catch (e) {
      console.log(e);
    }
  },
);

export const addVisitCount = createAsyncThunk(
  "battle/addVisitCount",
  async payload => {
    try {
      const docRef = await addDoc(collection(db, Collection.VISIT), {
        ...payload,
        created: serverTimestamp(),
      });
      payload["id"] = docRef.id;
      Promise.resolve(payload);
    } catch (e) {
      console.log(e);
    }
  },
);

export const updateSongs = createAsyncThunk(
  "battle/updateSongs",
  async (payload, { dispatch }) => {
    try {
      await setDoc(doc(db, Collection.SONGS, payload.doc), payload.body, {
        merge: true,
      });
      if (payload?.body?.battleId) {
        let to = [
          {
            email: emailConstants.ADMIN_EMAIL,
            name: emailConstants.ADMIN_NAME,
          },
        ];
        let name = payload?.displayName;
        name = name
          .toLowerCase()
          .replace(/(^|\s)\S/g, letter => letter.toUpperCase());
        emailConstants.ADMIN_NEW_BATTLE_NOTIFICATION_EMAIL = {
          ...emailConstants.ADMIN_NEW_BATTLE_NOTIFICATION_EMAIL,
          PARAGRAPH1: `We've got an exciting update! A new participant has registered for a battle. Let's give a warm welcome to ${name}.`,
          PARAGRAPH2: `Here are the details:<br>Name: ${name}<br>Email: ${payload?.email}<br>Joined Battle: ${payload?.battleTitle}`,
          PARAGRAPH3: `Let's make sure ${name} feels the energy of our community as she joins in the celebration.`,
        };
        let emailPayload = handleSendMail(
          to,
          emailConstants.ADMIN_NEW_BATTLE_NOTIFICATION_EMAIL,
        );
        dispatch(sendMail(emailPayload));
      }
    } catch (e) {
      console.log(e);
    }
  },
);

export const battleSlice = createSlice({
  name: "battle",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getAllDocs
    builder.addCase(getBattleItem.pending, state => {
      state.loading = true;
      state.battleItem = "";
    });
    builder.addCase(getBattleItem.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.length > 0) {
        state.battleItem = action.payload[0];
      } else {
        state.battleItem = "";
      }
    });
    builder.addCase(getBattleItem.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    builder.addCase(getSongs.pending, state => {
      state.loading = true;
      state.battleSongList = [];
    });
    builder.addCase(getSongs.fulfilled, (state, action) => {
      state.loading = false;
      state.battleSongList = action.payload;
    });
    builder.addCase(getSongs.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    builder.addCase(getUserLikedSongs.pending, state => {
      state.loading = true;
      state.userLikedSongs = [];
    });
    builder.addCase(getUserLikedSongs.fulfilled, (state, action) => {
      state.loading = false;
      state.userLikedSongs = action.payload;
    });
    builder.addCase(getUserLikedSongs.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
  },
});

export default battleSlice.reducer;
