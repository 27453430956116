const getCount = count => {
  if (count >= 1e6) {
    // Convert to millions (M)
    return (count / 1e6).toFixed(2) + "M";
  } else if (count >= 1e3) {
    // Convert to thousands (K)
    return (count / 1e3).toFixed(2) + "K";
  } else {
    // Display as is
    return count;
  }
};
export default getCount;

/* I have updated the code to use the toFixed method to limit the decimal places to 2, which can improve performance. */
