import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from " firebase";
import {
  collectionGroup,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import {
  updateDoc,
  increment,
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  query,
  orderBy,
  startAt,
  limit,
  getDocs,
  deleteDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Collection } from "constants/Collection";
import { handleSendMail } from "utils/handleSendMail";
import { emailConstants } from "constants/EmailConstants";
import {
  // getUser,
  sendMail,
  updateUser,
} from "modules/login/features/loginSlice";

const initialState = {
  loading: false,
  battleList: [],
  artistList: [],
  battleItem: "",
  battleSongsList: [],
  paidMembersList: [],
  totalSubscribedMembersList: [],
  totalVotesList: [],
  totalStreams: [],
  visitList: [],
};

//getting list of artists
export const getAllBattles = createAsyncThunk(
  "adminUser/getAllBattles",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.BATTLES),
        // where("visible", "==", true),
        orderBy("sortOrder", "desc"),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc?.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getAllBattles.fulfilled(newData));
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);
export const updateSortOrder = createAsyncThunk(
  "adminUser/updateSortOrder",
  async payload => {
    try {
      const updatePromises = payload?.updatedItems.map(async item => {
        await updateDoc(doc(db, Collection.BATTLES, item.id), {
          sortOrder: item.sortOrder,
        });
      });
      await Promise.all(updatePromises);
    } catch (e) {
      console.error(e);
      throw e; // Rethrow the error to be handled by the rejection of the thunk
    }
  },
);
//getting list of artists
export const getAllArtists = createAsyncThunk(
  "adminUser/getAllArtists",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.USERS),
        where("role", "==", "creator"),
        orderBy(payload.orderBy),
        startAt(payload.startAt),
        limit(payload.limit),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getAllArtists.fulfilled(newData));
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

//getting Battle Item
export const getBattleItem = createAsyncThunk(
  "adminUser/getBattleItem",
  async (payload, api) => {
    try {
      const docRef = doc(db, Collection.BATTLES, payload.doc);
      const docSnap = await getDoc(docRef);
      let obj = docSnap.data();
      obj["id"] = docSnap.id;
      return obj;
    } catch (e) {
      console.log(e);
    }
  },
);

//getting songs list of particular battle item
export const getBattleSongsList = createAsyncThunk(
  "adminUser/getBattleSongsList",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(
        collection(db, Collection.SONGS),
        where(payload.param, payload.condition, payload.target),
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getBattleSongsList.fulfilled(newData));
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);
// create battle
export const uploadBattle = createAsyncThunk(
  "adminUser/uploadBattle",
  async (payload, { dispatch }) => {
    try {
      const q10 = query(
        collection(db, Collection.BATTLES),
        where("visible", "==", true),
        orderBy("sortOrder", "desc"),
      );
      const count = (await getDocs(q10)).size;
      const docRef = await addDoc(collection(db, Collection.BATTLES), {
        ...payload.body,
        sortOrder: count + 1,
        created: serverTimestamp(),
        createdAt: serverTimestamp(),
      });
      payload["id"] = docRef.id;
      // set details for sending mail for artists
      emailConstants.BATTLE_ANNOUNCEMENT_EMAIL = {
        ...emailConstants.BATTLE_ANNOUNCEMENT_EMAIL,
        PARAGRAPH2: `${payload?.body?.title} (${payload?.body?.tagline}).${payload?.body?.shortDescription}`,
      };
      emailConstants.BATTLE_REMINDER_EMAIL = {
        ...emailConstants.BATTLE_REMINDER_EMAIL,
        PARAGRAPH2: `${payload?.body?.title} (${payload?.body?.tagline}).${payload?.body?.shortDescription}`,
      };
      const q = query(
        collection(db, Collection.USERS),
        where("role", "==", "creator"),
      );
      const querySnapshot = await getDocs(q);
      let battleRemainderPayload = [];
      querySnapshot.forEach(doc => {
        let to = []; //all musicians
        let obj = doc.data();
        let obj1 = {};
        obj1["email"] = obj?.email;
        obj1["name"] = obj?.displayName;
        to.push(obj1);
        let emailPayload = handleSendMail(
          to,
          emailConstants.BATTLE_ANNOUNCEMENT_EMAIL,
        );
        battleRemainderPayload.push(
          handleSendMail(to, emailConstants.BATTLE_REMINDER_EMAIL),
        );
        dispatch(sendMail(emailPayload));
      });
      // set details for sending mail for voters
      emailConstants.VOTER_BATTLE_EMAIL = {
        ...emailConstants.VOTER_BATTLE_EMAIL,
        PARAGRAPH2: `${payload?.body?.title} (${payload?.body?.tagline}).${payload?.body?.shortDescription}`,
      };
      emailConstants.VOTER_REMAINDER_EMAIL = {
        ...emailConstants.VOTER_REMAINDER_EMAIL,
        PARAGRAPH2: `${payload?.body?.title} (${payload?.body?.tagline}).${payload?.body?.shortDescription}`,
      };
      const q1 = query(
        collection(db, Collection.USERS),
        where("role", "==", "voter"),
      );
      const querySnapshot1 = await getDocs(q1);
      let voterEmailPayload = [];
      querySnapshot1.forEach(doc => {
        let to = []; //all musicians
        let obj = doc.data();
        let obj1 = {};
        obj1["email"] = obj?.email;
        obj1["name"] = obj?.displayName;
        to.push(obj1);
        let emailPayload = handleSendMail(
          to,
          emailConstants.VOTER_BATTLE_EMAIL,
        );
        voterEmailPayload.push(
          handleSendMail(to, emailConstants.VOTER_REMAINDER_EMAIL),
        );
        dispatch(sendMail(emailPayload));
      });
      return {
        battleRemainderPayload: battleRemainderPayload,
        voterEmailPayload: voterEmailPayload,
      };
      // Promise.resolve(payload);
    } catch (e) {
      console.log(e);
    }
  },
);

// create battle
export const updateBattle = createAsyncThunk(
  "adminUser/updateBattle",
  async (payload, api) => {
    try {
      await setDoc(doc(db, Collection.BATTLES, payload.doc), payload.body, {
        merge: true,
      });
      Promise.resolve(payload);
    } catch (e) {
      console.log(e);
    }
  },
);

// delete battle
export const deleteBattle = createAsyncThunk(
  "adminUser/deleteBattle",
  async (payload, api) => {
    try {
      await deleteDoc(doc(db, Collection.BATTLES, payload.doc));
    } catch (e) {
      console.log(e);
    }
  },
);

// update xp count
export const updateXPDetails = createAsyncThunk(
  "adminUser/updateXPDetails",
  async (payload, { dispatch }) => {
    try {
      const sub = doc(db, Collection.USERS, payload.doc);
      await updateDoc(sub, {
        xp: increment(parseInt(payload.incrementValue)),
      });

      if (
        payload?.incrementValue === -250000 ||
        payload?.incrementValue === -200000
        //static xp value for single entry also changed in related files
      ) {
        await addDoc(
          collection(db, Collection.USERS, payload.doc, Collection.XP),
          {
            incrementValue: payload?.incrementValue,
            battleId: payload?.battleId || null,
            created: serverTimestamp(),
          },
        );
      }
      if (payload?.incrementValue === -250000) {
        const docSnap = await getDoc(sub);
        let obj = docSnap.data();
        obj["id"] = docSnap.id;

        const today = new Date();
        const futureDate = new Date(today);
        futureDate.setDate(today.getDate() + 30);
        let payload2 = {
          doc: payload?.doc,
          body: {
            ...obj,
            xpDueDate: futureDate,
          },
        };
        dispatch(updateUser(payload2));
      }
    } catch (e) {
      console.log(e);
    }
  },
);

// add notification
export const addNotifications = createAsyncThunk(
  "adminUser/addNotifications",
  async (payload, api) => {
    try {
      const docRef = await addDoc(collection(db, Collection.NOTIFICATIONS), {
        ...payload.body,
        created: serverTimestamp(),
      });
      payload["id"] = docRef.id;
    } catch (e) {
      console.log(e);
    }
  },
);

// add notification
export const updateWinner = createAsyncThunk(
  "adminUser/updateWinner",
  async (payload, { dispatch }) => {
    try {
      await setDoc(doc(db, Collection.BATTLES, payload.doc), payload.body, {
        merge: true,
      });
    } catch (e) {
      console.log(e);
    }
  },
);

// add notification
export const setArtistWinner = createAsyncThunk(
  "adminUser/setArtistWinner",
  async (payload, { dispatch }) => {
    try {
      await setDoc(
        doc(
          db,
          Collection.USERS,
          payload.parentDoc,
          Collection.AWARDS,
          payload.doc,
        ),
        payload.body,
      );
      // set details for sending mail

      if (+payload?.body?.award === 1) {
        // get winner details
        let winner = "";
        const q1 = query(
          collection(db, Collection.USERS),
          where("id", "==", payload.parentDoc),
        );
        const querySnapshot1 = await getDocs(q1);
        querySnapshot1.forEach(doc => {
          winner = doc.data();
        });
        emailConstants.WINNER_ANNOUNCEMENT_EMAIL = {
          ...emailConstants.WINNER_ANNOUNCEMENT_EMAIL,
          PARAGRAPH1: `The moment we've all been waiting for has arrived! We're excited to announce the winner of the recent music battle: ${winner?.displayName} . Congratulations on this incredible achievement! `,
          PARAGRAPH2: `${winner?.displayName} won ${winner?.xp} xp points `,
        };
        const q = query(collection(db, Collection.USERS));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(doc => {
          let to = []; //all musicians
          let obj = doc.data();
          let obj1 = {};
          obj1["email"] = obj?.email;
          obj1["name"] = obj?.displayName;
          to.push(obj1);
          let emailPayload = handleSendMail(
            to,
            emailConstants.WINNER_ANNOUNCEMENT_EMAIL,
          );
          dispatch(sendMail(emailPayload));
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
);

export const getPaidMembersList = createAsyncThunk(
  "adminUser/getPaidMembersList",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(collection(db, Collection.USERS));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(doc => {
        let obj = doc.data();

        obj["id"] = doc.id;
        let isPaidMember =
          obj?.battleEntryPlan || obj?.subscription?.subscriptionId !== null;

        if (isPaidMember) {
          arr.push(obj);
        }
      });
      // eslint - disable - next - line;
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(
          getPaidMembersList.fulfilled(
            newData?.filter(
              item =>
                item?.subscription?.subscriptionId !== null ||
                item?.battleEntryPlan,
            ),
          ),
        );
      });

      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getTotalSubscribedMembersList = createAsyncThunk(
  "adminUser/getTotalSubscribedMembersList",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(collection(db, Collection.USERS));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        obj?.subscription && arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(
          getPaidMembersList.fulfilled(
            newData?.filter(item => item?.subscription),
          ),
        );
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getTotalVotes = createAsyncThunk(
  "adminUser/getTotalVotes",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const usersQuery = query(collectionGroup(db, Collection.LIKES));
      const usersSnapshot = await getDocs(usersQuery);
      usersSnapshot.forEach(doc => {
        let obj = { ...doc.data() };
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(usersQuery, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getTotalVotes.fulfilled(newData));
      });

      return arr;
    } catch (error) {
      console.error("Error fetching total votes:", error);
      throw error; // Rethrow the error to be caught by the caller
    }
  },
);

export const getTotalStreams = createAsyncThunk(
  "adminUser/getTotalStreams",
  async (payload, { dispatch }) => {
    try {
      const arr = [];
      const usersQuery = query(collectionGroup(db, Collection.LISTENS));
      const usersSnapshot = await getDocs(usersQuery);

      for (const doc1 of usersSnapshot.docs) {
        const obj = { ...doc1.data() };
        obj["id"] = doc1.id;
        const docRef = doc(db, Collection.SONGS, obj.songDoc);
        const docSnap = await getDoc(docRef);
        const obj1 = docSnap.data();
        obj["battleId"] = obj1?.battleId;
        arr.push(obj);
      }
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(usersQuery, snapshot => {
        const newData = snapshot.docs.map(async doc1 => {
          const songDocSnap = await getDoc(
            doc(db, Collection.SONGS, doc1.data().songDoc),
          );
          return {
            id: doc1.id,
            battleId: songDocSnap.data().battleId,
            ...doc1.data(),
          };
        });
        Promise.all(newData).then(data => {
          dispatch(getTotalStreams.fulfilled(data));
        });
      });

      return arr;
    } catch (error) {
      console.error("Error fetching total streams:", error);
      throw error;
    }
  },
);

export const getVisitList = createAsyncThunk(
  "adminUser/getVisitList",
  async (payload, { dispatch }) => {
    try {
      let arr = [];
      const q = query(collection(db, Collection.VISIT));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(doc => {
        let obj = doc.data();
        obj["id"] = doc.id;
        arr.push(obj);
      });
      // eslint-disable-next-line
      const unsubscribe = onSnapshot(q, snapshot => {
        const newData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch(getVisitList.fulfilled(newData));
      });
      return arr;
    } catch (e) {
      console.log(e);
    }
  },
);

export const adminUserSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getAllDocs
    builder.addCase(getAllBattles.pending, state => {
      state.loading = true;
      state.battleList = [];
    });
    builder.addCase(getAllBattles.fulfilled, (state, action) => {
      state.loading = false;
      state.battleList = action.payload;
    });
    builder.addCase(getAllBattles.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    // getAllDocs
    builder.addCase(getAllArtists.pending, state => {
      state.loading = true;
      state.artistList = [];
    });
    builder.addCase(getAllArtists.fulfilled, (state, action) => {
      state.loading = false;
      state.artistList = action.payload;
    });
    builder.addCase(getAllArtists.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    // get battle item
    builder.addCase(getBattleItem.pending, state => {
      state.loading = true;
      state.battleItem = "";
    });
    builder.addCase(getBattleItem.fulfilled, (state, action) => {
      state.loading = false;
      state.battleItem = action.payload;
    });
    builder.addCase(getBattleItem.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    // get battle songs list
    builder.addCase(getBattleSongsList.pending, state => {
      state.loading = true;
      state.battleSongsList = [];
    });
    builder.addCase(getBattleSongsList.fulfilled, (state, action) => {
      state.loading = false;
      state.battleSongsList = action.payload;
    });
    builder.addCase(getBattleSongsList.rejected, (state, action) => {
      state.loading = false;
      toast.error(action?.error.message);
    });
    builder.addCase(getPaidMembersList.pending, state => {
      state.loading = true;
      // state.paidMembersList = [];
    });
    builder.addCase(getPaidMembersList.fulfilled, (state, action) => {
      state.loading = false;

      state.paidMembersList = action.payload;
    });
    builder.addCase(getPaidMembersList.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getTotalSubscribedMembersList.pending, state => {
      state.loading = true;
      state.totalSubscribedMembersList = [];
    });
    builder.addCase(
      getTotalSubscribedMembersList.fulfilled,
      (state, action) => {
        state.loading = false;
        state.totalSubscribedMembersList = action.payload;
      },
    );
    builder.addCase(getTotalSubscribedMembersList.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getTotalVotes.pending, state => {
      state.loading = true;
      state.totalVotesList = [];
    });
    builder.addCase(getTotalVotes.fulfilled, (state, action) => {
      state.loading = false;
      state.totalVotesList = action.payload;
    });
    builder.addCase(getTotalVotes.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getTotalStreams.pending, state => {
      state.loading = true;
      state.totalStreams = [];
    });
    builder.addCase(getTotalStreams.fulfilled, (state, action) => {
      state.loading = false;
      state.totalStreams = action.payload;
    });
    builder.addCase(getTotalStreams.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getVisitList.pending, state => {
      state.loading = true;
      state.visitList = [];
    });
    builder.addCase(getVisitList.fulfilled, (state, action) => {
      state.loading = false;
      state.visitList = action.payload;
    });
    builder.addCase(getVisitList.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default adminUserSlice.reducer;
